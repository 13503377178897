.container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container a{
    text-decoration: none;
    color: black;
    display: inline;
}