.hero-section{
    width: 100%;
    height: 100vh;
    position: absolute;
}

.pay-off{
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-100%);
    transform-origin: bottom;
}

.works-button{
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: all 1s cubic-bezier(.215, .61, .355, 1);
}

.line { 
    width: 1px;
    background-color: white;
    height: 45px; 
}

.works-button:hover > .line{
    cursor: pointer;
    width: 2px;
    transition: all 1s linear;
}









/* .payoff {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Eagle Lake';
    font-size: 48px;
    flex-direction: column;
}

.payoff-mobile {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Eagle Lake';
    font-size: 48px;
    flex-direction: column;
}

.content{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: 'Eagle Lake';
    font-size: 32px;
}

.line {
    stroke: #a88d2e;
}

.text {
    white-space: normal;
    display: flex;
}

.theLine {
    stroke-dasharray: 1650;
    stroke-dashoffset: 1650;
    stroke: #a88d2e;
}

.ball {}

.ball01,
.ball02,
.ball03,
.ball04 {
    fill: #a88d2e;
    opacity: 0;
}

.text {}

.text01,
.text02,
.text03 {
    fill: black;
    font-family: 'Eagle Lake';
    font-size: 12px;
}

.text01-mobile,
.text02-mobile,
.text03-mobile {
    fill: black;
    font-family: 'Eagle Lake';
    font-size: 18px;
}


.normal-page{
    width: 100%;
    height: 100vh;
    text-align: center;
    color: black;
} */