@import url('https://fonts.googleapis.com/css2?family=Eagle+Lake&family=Roboto&display=swap');

*{
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1c1d25;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main{
  margin: 2rem auto;
  text-align: center;
}

svg{
  margin-bottom: -5px;
}


/* ! Reset */
body, html{ font-size: 16px; font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif; box-sizing: border-box;margin: 0;padding: 0;}
*,*:before,*:after{ box-sizing: border-box; }
h1,h2,h3,h4,h5,h6, p, ol, ul, pre{margin: 0;padding: 0; color: white;}
ol,ul{list-style-type: none;}
img{height: auto;}


/* ! Title System */
h1 {font-size: 54px;margin-bottom: 20px}
h2 {font-size: 44px;margin-bottom: 20px}
h3 {font-size: 34px;margin-bottom: 20px}
h4{font-size: 24px;margin-bottom: 20px}
h3, p {font-size: 16px;margin-bottom: 20px}

.swiper-wrapper{
  align-items: center;
}
.css-5xe99f-MuiLinearProgress-bar1{
  background-color: rgb(168, 87, 0) !important;
}