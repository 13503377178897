.hrader{
    width: 100vw;
    background-color: beige;
}

.list {
    display: flex;
    justify-content: center;
    width: 100%;
}

.list a{
    list-style-type: none;
    text-decoration: none;
    color: #836e24; 
    margin: 0 20px;
    font-size: 40px;
}

.list a:hover,
.list a.active{
    color: #a88d2e;
    text-decoration: underline;
}




