.imageContainer {
    position: relative;
    width: 100%;
    background-color: black;
    /* filter: brightness(70%); */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-radius: 2px 2px 10px 10px;
}

.textOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 0.5);
}

.img_cover{
    object-fit: cover;
    width: 100%;
    height: 100%;
    opacity: 0.6;
}