.preloader{
    height: 100vh;
    width: 100vw;
    background-color: #282c34;
    color: #ffffffa1;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 55;
    display: none;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.preloader .texts-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    width: 200px;
    font-size: 20px;
    overflow: hidden;
    color: #ffffffa1;
}

.netflix{
    position: absolute;
    font-size: 40px;
    opacity: 1;
    
}

.scroll{
    position: absolute;
    top: 90%;
    
    opacity: 0;
    animation: my-animation 0.75s infinite ease-in-out;
    font-size: 30px;
}

.div{
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: #ffffff;
}

@keyframes my-animation{

  0% {transform: rotate(0deg);}
  20%{transform: rotate(0deg);}
  40%{transform: rotate(10deg);}
  60%{transform: rotate(-10deg);}
  80%{transform: rotate(0deg);}
  100% {transform: rotate(0deg);}

}